import React, { useState, useEffect } from 'react';
import { Frame, Page, ResourceList, Text, Thumbnail, Layout, Modal, Button, Card, Select, Toast, Banner, FormLayout, TextField } from '@shopify/polaris';

export default function ProductList() {

  const [storeDomain] = useState(new URL(window.location).searchParams.get("shop"));
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [fields, setFields] = useState([{ shopifyProductId: '', discordRole: '' }]);
  const [fields2, setFields2] = useState([{ shopifyOrderAmount: '', discordRole: '' }]);
  const [selectedProductIdIndex, setSelectedProductIdIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [discordRoles, setDiscordRoles] = useState([]);
  const [activeProStatus, setActiveProStatus] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    async function fetchSettings() {
      try {
        const response = await fetch(`/api/get_data?shop=${storeDomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data.subscriptions) {
            data.subscriptions.forEach(function(recurr) {
                if(recurr.name === "Premium Plan" && recurr.status === "active"){
                    setActiveProStatus(true);
                }
            });
          }
          if (data.integrations && data.integrations.discord_products && data.integrations.discord_products[0]) {
            setFields(data.integrations.discord_products.map(({ shopifyProductId, discordRole }) => ({
              shopifyProductId,
              discordRole: discordRole
            })));
            setFields2(data.integrations.discord_orders.map(({ shopifyOrderAmount, discordRole }) => ({
              shopifyOrderAmount,
              discordRole: discordRole
            })));
          } else {
            setFields([{ shopifyProductId: '', discordRole: '' }]);
            setFields2([{ shopifyOrderAmount: '', discordRole: '' }]);
          }
        } else {
          console.error('Failed to fetch products');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }
    fetchSettings();

    async function fetchProducts() {
      try {
        const response = await fetch(`/api/products_details?shop=${storeDomain}`, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
          },
        });
        if (response.ok) {
          const data = await response.json();
          setProducts(data);
        } else {
          console.error('Failed to fetch products');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }
    fetchProducts();
    
    async function fetchDiscordRoles() {
      try {
        const response = await fetch(`/api/fetch_all_roles?shop=${storeDomain}`,{
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
          },
        });
        if (response.ok) {
          const data = await response.json();
          setDiscordRoles(data);
        } else {
          console.error('Failed to fetch discord roles');
        }
      } catch (error) {
        console.error('Error fetching discord roles:', error);
      }
    }
    fetchDiscordRoles();
    
  }, [storeDomain, selectedProducts.length]);

  useEffect(() => {
    if (products.length > 0 && selectedProducts.length === 0) {
      setSelectedProducts([products[0].id]);
    }
  }, [products, selectedProducts]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleInputChange = (index, event, isFields2 = false) => {
    if (event && event.target) {
      if (isFields2) {
        const values = [...fields2];
        values[index][event.target.name] = event.target.value;
        setFields2(values);
      } else {
        const values = [...fields];
        values[index][event.target.name] = event.target.value;
        setFields(values);
      }
    }
  };  

  const handleAddField = (isFields2 = false) => {
    if (isFields2) {
      setFields2([...fields2, { shopifyOrderAmount: '', discordRole: '' }]);
    } else {
      setFields([...fields, { shopifyProductId: '', discordRole: '' }]);
    }
  };

  const handleRemoveField = (index, isFields2 = false) => {
    if (isFields2) {
      const values = [...fields2];
      values.splice(index, 1);
      setFields2(values);
    } else {
      const values = [...fields];
      values.splice(index, 1);
      setFields(values);
    }
  };

  const handleOpenProductListModal = (index) => {
    setSelectedProductIdIndex(index);
    setModalOpen(true);
  };

  const handleProductSelection = (productId) => {
    const values = [...fields];
    values[selectedProductIdIndex].shopifyProductId = productId;
    setFields(values);
    setModalOpen(false);
  };

  const handleSubmit = async () => {
    const hasBlankProductIds = fields.some(field => field.shopifyProductId === '');
    if (hasBlankProductIds && !fields2.length) {
      console.error('Please select a product for all fields');
      return;
    }

    const discord_products = fields;
    const discord_orders = fields2;
    console.log('Fields:', discord_products, discord_orders);

    setActive((active) => !active);
    const response = await fetch(`/api/update_config?shop=${storeDomain}`, {
        method: "POST", 
        headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
        },
        body: JSON.stringify({ discord_products, discord_orders }),
    });

    if (response.ok) {
        console.log(response.status);
    } else {
        console.error('Failed to save settings');
    }
    return;
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDismiss = async () => {
    setActive((active) => !active);
  };

  const toastMarkup = active ? (
    <Toast content="Saved Products Settings Successfully" onDismiss={handleDismiss} />
  ) : null;

  return (
    <div style={{ height: '250px' }}>
      <Frame>
        <Page
          title="Product Integration"
          primaryAction={{ content: 'Save', onAction: handleSubmit }}
        >
          <FormLayout>
            {((activeProStatus && activeProStatus === true)) ? (
                <></>
            ) : (
              <Banner tone="info"><b>Upgrade Premium Plan</b> to use Product Integration Features</Banner>
            )}
            
          <Layout>
          <Layout.Section>
            <Modal
              open={modalOpen}
              onClose={closeModal}
              title="All Products"
              primaryAction={{
                content: 'Select Products',
                onAction: () => {},
              }}
              secondaryActions={[
                {
                  content: 'Close',
                  onAction: closeModal,
                },
              ]}
            >
              <Modal.Section>
                <TextField
                  label="Search products"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  autoComplete="off"
                  placeholder="Search by product title"
                />
              <Modal.Section></Modal.Section>
                <ResourceList
                  resourceName={{ singular: 'product', plural: 'products' }}
                  items={filteredProducts}
                  renderItem={(item) => {
                    const { id, title, images, price } = item;
                    const media = images && images.length > 0 ? (
                      <Thumbnail
                        source={images[0].src}
                        alt={`Thumbnail for ${title}`}
                        size="medium"
                      />
                    ) : (
                      <Thumbnail
                        source="https://via.placeholder.com/150"
                        alt="Placeholder"
                        size="medium"
                      />
                    );
                    return (
                      <ResourceList.Item
                        key={id}
                        id={id}
                        media={media}
                        onClick={() => handleProductSelection(id)}
                        accessibilityLabel={`Select ${title}`}
                      >
                        <h3>
                          <Text variant="headingMd" fontWeight="bold" as="span">
                            {title}
                          </Text>
                        </h3>
                        <div>{price}</div>
                      </ResourceList.Item>
                    );
                  }}
                />
              </Modal.Section>
            </Modal>

            {/* Product-based role integrations */}
            <Card sectioned title="Products-based role integrations">
              <Text variant="headingLg" as="h5">
                Products based role integration
              </Text><br />
              {fields.map((field, index) => (
                <FormLayout key={index}>
                  <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'flex-end' }}>
                    <div style={{ display: 'inline', marginRight: '20px' }}>
                      <TextField
                        label={`Selected Product ${index + 1}`}
                        value={products.find(product => product.id === field.shopifyProductId)?.title || ''}
                        onFocus={() => handleOpenProductListModal(index)}
                        placeholder="Select a product"
                        readOnly
                      />
                    </div>
                    <div style={{ display: 'inline', marginRight: '20px' }}>
                      <Select
                        label="Select a Discord Role"
                        placeholder="Select Role"
                        options={discordRoles.map(role => ({ label: role.name, value: role.id }))}
                        value={field.discordRole}
                        onChange={(value) => handleInputChange(index, { target: { name: 'discordRole', value } })}
                      />
                    </div>
                    <div style={{ display: 'inline', marginRight: '20px' }}>
                      <Button variant="primary" tone="critical" onClick={() => handleRemoveField(index)}>Remove</Button>
                    </div>
                  </div>
                </FormLayout>
              ))}
              <Button variant="primary" tone="success" onClick={() => handleAddField()} disabled={!activeProStatus}>+ Add Field</Button>
            </Card><br />
            
            {/* Order-based role integrations */}
            <Card sectioned title="Orders-based role integrations">
              <Text variant="headingLg" as="h5">
                Orders-Amount based role integration
              </Text><br />
              {fields2.map((field2, index) => (
                <FormLayout key={index}>
                  <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'flex-end' }}>
                    <div style={{ display: 'inline', marginRight: '20px' }}>
                      <TextField
                        label={`Minimum Order Amount ${index + 1}`}
                        type="number"
                        name="shopifyOrderAmount"
                        value={field2.shopifyOrderAmount}
                        onChange={(value) => handleInputChange(index, { target: { name: 'shopifyOrderAmount', value } }, true)}
                        placeholder="Enter Minimum Order Amount"
                      />
                    </div>
                    <div style={{ display: 'inline', marginRight: '20px' }}>
                      <Select
                        label="Select a Discord Role"
                        placeholder="Select Role"
                        options={discordRoles.map(role => ({ label: role.name, value: role.id }))}
                        value={field2.discordRole}
                        onChange={(value) => handleInputChange(index, { target: { name: 'discordRole', value } }, true)}
                      />
                    </div>
                    <div style={{ display: 'inline', marginRight: '20px' }}>
                      <Button variant="primary" tone="critical" onClick={() => handleRemoveField(index, true)}>Remove</Button>
                    </div>
                  </div>
                </FormLayout>
              ))}
              <Button variant="primary" tone="success" onClick={() => handleAddField(true)} disabled={!activeProStatus}>+ Add Field</Button>
            </Card>
          </Layout.Section>
        </Layout>
        </FormLayout>
        {toastMarkup}
      </Page>
    </Frame>
    </div>
  );
}
