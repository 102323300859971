import React, { useState, useEffect } from 'react';
import { Card, Layout, FormLayout, TextField, Page, Frame, Toast, Text, RadioButton, Checkbox, Divider, ColorPicker, hsbToHex, Spinner } from '@shopify/polaris';
import DiscordButtonEmbed from './embed';
import getContentBasedOnStyle from './customizepreview';

export default function Customize() {

  const [storeDomain] = useState(new URL(window.location).searchParams.get("shop"));
  const [iconColor, setIconColor] = useState({ hue: 227, brightness: 58, saturation: 65, });
  const [belowLogin, setBelowLogin] = useState(false);
  const [buttonStyle, setButtonStyle] = useState('long');
  const [iconWidth, setIconWidth] = useState(50);
  const [iconHeight, setIconHeight] = useState(50);
  const [iconSize, setIconSize] = useState(25);
  const [iconSpace, setIconSpace] = useState(10);
  const [iconCurve, setIconCurve] = useState(5);
  const [iconText, setIconText] = useState("Login with ");
  const [supportText, setSupportText] = useState("---- OR ----");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const hsbToHexColor = (hsbToHex(iconColor) === '#-e7180-b43db39c6') ? '#7289da' : hsbToHex(iconColor);
  const iconsColor = { "discord": hsbToHexColor };
  const socialMediaIcons = [
    { style: 'long', label: 'Long Button' },
    { style: 'circle', label: 'Circle Icon' },
    { style: 'curve', label: 'Curve Edge Icon' },
    { style: 'whitelong', label: 'White Long Button' },
    { style: 'whitecircle', label: 'White Circle Icon' },
    { style: 'whitecurve', label: 'White Curve Edge' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/get_data?shop=${storeDomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data) {
            const dataStore = data.settings.discord_customization;
            setIconColor(dataStore.iconColor || { hue: 227, brightness: 58, saturation: 65, });
            setButtonStyle(dataStore.buttonStyle);
            setIconWidth(dataStore.iconWidth);
            setIconHeight(dataStore.iconHeight);
            setIconSize(dataStore.iconSize);
            setIconCurve(dataStore.iconCurve);
            setIconSpace(dataStore.iconSpace);
            setIconText(dataStore.iconText);
            setSupportText(dataStore.supportText);
            setBelowLogin(dataStore.belowLogin);
          }
          console.log(response.status);
        } else {
          console.log(response.status);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (loading) {
      fetchData();
    }

  }, [storeDomain, loading]);

  const handleSubmit = async () => {

    setSaving(true);
    const discord_customization = {
      iconsColor: hsbToHexColor,
      iconColor,
      iconWidth,
      iconHeight,
      iconSize,
      iconCurve,
      iconSpace,
      iconText,
      supportText,
      buttonStyle,
      belowLogin,
    };

    const response = await fetch(`/api/update_config?shop=${storeDomain}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'x-shopify-shop-domain': storeDomain
      },
      body: JSON.stringify({discord_customization}),
    });

    if (response.ok) {
      setToastMessage('Settings saved successfully.');
    } else {
      setToastMessage('Failed to save settings.');
    }
    setShowToast(true);
    setSaving(false);
  };

  const handleInputChange = (key, value) => {
    switch (key) {
      case 'buttonStyle':
        setButtonStyle(value);
        break;
      case 'iconWidth':
        setIconWidth(value);
        break;
      case 'iconHeight':
        setIconHeight(value);
        break;
      case 'iconSize':
        setIconSize(value);
        break;
      case 'iconSpace':
        setIconSpace(value);
        break;
      case 'iconCurve':
        setIconCurve(value);
        break;
      case 'iconText':
        setIconText(value);
        break;
      case 'supportText':
        setSupportText(value);
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ height: '500px' }}>
      <Frame>
        <Page
          title="Customize Icons"
          primaryAction={{
            content: saving ? <Spinner size="small" color="teal" /> : 'Save',
            onAction: handleSubmit,
          }}
        >
          <Layout>
            <Layout.Section secondary>
              <Card>
                <Text variant="headingMd" as="h2" alignment="center">Edit Icon Settings</Text><br />
                  <FormLayout>
                    <div style={{ display: 'flex', gap: '20%' }}>
                      <ColorPicker
                        color={iconColor}
                        onChange={setIconColor}
                      />
                      <Card>
                        <Text variant="headingMd" as="h2" alignment="center">Preview Social Icons</Text><br />
                          <center>
                            {getContentBasedOnStyle(buttonStyle, iconSpace, iconWidth, iconHeight, iconSize, iconCurve, iconsColor, iconText)}
                            <p>{supportText}</p>
                          </center>
                      </Card>
                    </div>
                      {socialMediaIcons.map((icon) => (
                        <RadioButton
                          key={icon.style}
                          label={icon.label}
                          checked={buttonStyle === icon.style}
                          onChange={() => handleInputChange('buttonStyle', icon.style)}
                        />
                      ))}
                    <Divider />
                    <div style={{ display: 'flex', gap: '16px' }}>
                    <TextField
                      label="Icon Width"
                      type="number"
                      value={iconWidth}
                      onChange={(value) => handleInputChange('iconWidth', value)}
                      autoComplete="off"
                    />
                    <TextField
                      label="Icon Height"
                      type="number"
                      value={iconHeight}
                      onChange={(value) => handleInputChange('iconHeight', value)}
                      autoComplete="off"
                    />
                    <TextField
                      label="Icon Size"
                      type="number"
                      value={iconSize}
                      onChange={(value) => handleInputChange('iconSize', value)}
                      autoComplete="off"
                    />
                    </div><div style={{ display: 'flex', gap: '16px' }}>
                    <TextField
                      label="Icon Space"
                      type="number"
                      value={iconSpace}
                      onChange={(value) => handleInputChange('iconSpace', value)}
                      autoComplete="off"
                    />
                    <TextField
                      label="Icon Curve"
                      type="number"
                      value={iconCurve}
                      onChange={(value) => handleInputChange('iconCurve', value)}
                      autoComplete="off"
                    />
                    </div><div style={{ display: 'flex', gap: '16px' }}>  
                    <TextField
                      label="Icon Text"
                      value={iconText}
                      onChange={(value) => handleInputChange('iconText', value)}
                      autoComplete="off"
                    />
                    <TextField
                      label="Support Text"
                      value={supportText}
                      onChange={(value) => handleInputChange('supportText', value)}
                      autoComplete="off"
                    />
                    </div>
                    <Divider />
                    <Checkbox
                      label="Show Below Login Form"
                      checked={belowLogin}
                      onChange={setBelowLogin}
                    />
                  </FormLayout>
              </Card>
            </Layout.Section>
          </Layout><br />
          <DiscordButtonEmbed />
          {showToast && (
            <Toast
              content={toastMessage}
              onDismiss={() => setShowToast(false)}
            />
          )}
        </Page>
        <Page></Page>
        <Page></Page>
        <Page></Page>
      </Frame>
    </div>
  );
};
