const getContentBasedOnStyle = (style, iconSpace, iconWidth, iconHeight, iconSize, iconCurve, iconsColor, iconText) => {
    
    const socialIconsArray = ['discord'];
    const commonStyle = {
      overflow: 'hidden',
      margin: `${iconSpace}px`,
      width: `${iconWidth}px`,
      height: `${iconHeight}px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    switch (style) {
      case 'long':
        return (
          <div>
            {socialIconsArray.map((icon, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: `${iconSpace}px 0px`,
                borderRadius: `${iconCurve}px`,
                padding: '10px',
                width: `${Number(iconWidth) + 200}px`,
                height: `${iconHeight}px`,
                color: 'white',
                backgroundColor: `${iconsColor[icon]}`,
              }}
              className="second-button">
              <img
                src={require(`../assets/${icon}_icon.png`)}
                alt={icon}
                style={{ width: `${iconSize}px`, height: `${Number(iconSize) - 5}px`, marginRight: '10px' }}
              />
              <span style={{ fontSize: `${Number(iconSize) - 10}px` }}>{iconText}{capitalizeFirstLetter(icon)}</span>
            </div>
          ))}
          </div>
        );
      case 'whitelong':
        return (
          <div>
            {socialIconsArray.map((icon, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid black',
                margin: `${iconSpace}px 0px`,
                borderRadius: `${iconCurve}px`,
                padding: '10px',
                backgroundColor: 'white',
                width: `${Number(iconWidth) + 200}px`,
                height: `${iconHeight}px`,
              }}
            >
              <img
                src={require(`../assets/${icon}_white.png`)}
                alt={icon}
                style={{ width: `${iconSize}px`, height: `${Number(iconSize) - 5}px`, marginRight: '10px' }}
              />
              <span style={{ fontSize: `${Number(iconSize) - 10}px` }}>{iconText}{capitalizeFirstLetter(icon)}</span>
            </div>
            ))}
          </div>
        );
      case 'curve':
        return (
          <div>
            {socialIconsArray.map((icon, index) => (
              <div
                key={index}
                style={{
                  ...commonStyle,
                  borderRadius: `${iconCurve}px`,
                  color: 'white',
                  backgroundColor: `${iconsColor[icon]}`,
                }}
              >
                <img
                  src={require(`../assets/${icon}_icon.png`)}
                  alt={icon}
                  style={{ width: `${iconSize}px`, height: `${Number(iconSize) - 5}px` }}
                />
              </div>
            ))}
          </div>
        );
      case 'circle':
        return (
          <div>
            {socialIconsArray.map((icon, index) => (
            <div
              key={index}
              style={{
                ...commonStyle,
                borderRadius: '50%',
                color: 'white',
                backgroundColor: `${iconsColor[icon]}`,
              }}
            >
              <img
                src={require(`../assets/${icon}_icon.png`)}
                alt={icon}
                style={{ width: `${iconSize}px`, height: `${Number(iconSize) - 5}px` }}
              />
            </div>
            ))}
          </div>
        );
      case 'whitecurve':
        return (
          <div>
            {socialIconsArray.map((icon, index) => (
              <div
                key={index}
                style={{
                  ...commonStyle,
                  borderRadius: `${iconCurve}px`,
                  border: '1px solid black',
                }}
              >
                <img
                  src={require(`../assets/${icon}_white.png`)}
                  alt={icon}
                  style={{ width: `${iconSize}px`, height: `${Number(iconSize) - 5}px` }}
                />
              </div>
            ))}
          </div>
        );
      case 'whitecircle':
        return (
          <div>
            {socialIconsArray.map((icon, index) => (
            <div
              key={index}
              style={{
                ...commonStyle,
                borderRadius: '50%',
                border: '1px solid black',
              }}
            >
              <img
                src={require(`../assets/${icon}_white.png`)}
                alt={icon}
                style={{ width: `${iconSize}px`, height: `${Number(iconSize) - 5}px` }}
              />
            </div>
            ))}
          </div>
        );
      default:
        return (
          <>
            No Apps Is Activated
          </>
        );
    }
};

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default getContentBasedOnStyle;