import React, { useState, useEffect } from 'react';
import '@shopify/polaris/build/esm/styles.css';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider, Frame, Page, Tabs, Image, Tooltip } from '@shopify/polaris';
import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react';
import Configuration from "./views/index";
import Customize from "./views/customize";
import Integrations from "./views/integration";
import ProductList from "./views/productlist";
import Notification from "./views/notification";
import PricingPlan from "./views/pricing";
import Guidelines from "./views/guidelines";

const App = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [config, setConfig] = useState(null);

  const handleTabChange = (selectedTabIndex) => {
    setSelectedTab(selectedTabIndex);
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('/config');
        if (!response.ok) {
          throw new Error('Failed to fetch App Bridge config');
        }
        const data = await response.json();
        const params = new URLSearchParams(window.location.search);
        const host = params.get('host');
        
        setConfig({
          apiKey: data.apiKey,
          shopOrigin: data.shopOrigin,
          host: host,
          forceRedirect: true,
          webVitals: false, // Disable web vitals
        });
      } catch (error) {
        console.error('Error fetching App Bridge config:', error);
      }
    };
    fetchConfig();
  }, []);

  // if (!config) {
  //   return <div>Loading...</div>;
  // }

  return (
    <AppProvider i18n={enTranslations}>
      {/* <AppBridgeProvider config={config}> */}
        <Frame sandbox="allow-scripts allow-same-origin allow-popups allow-forms">
          <Page>
            <Tabs
              tabs={[
                {
                  id: "configuration",
                  content: "Discord Configuration",
                },
                {
                  id: "customize",
                  content: "Design Icons",
                },
                {
                  id: "integrations",
                  content: "Member & Role Integration",
                },
                {
                  id: "productintegration",
                  content: "Product Integration",
                },
                {
                  id: "notification",
                  content: "Notification",
                },
                {
                  id: "pricing",
                  content: "Pricing Plan",
                },
                {
                  id: "guidelines",
                  content: "User Guide",
                },
              ]}
              selected={selectedTab}
              onSelect={handleTabChange}
            >
              {selectedTab === 0 && <Configuration />}
              {selectedTab === 1 && <Customize />}
              {selectedTab === 2 && <Integrations />}
              {selectedTab === 3 && <ProductList />}
              {selectedTab === 4 && <Notification />}
              {selectedTab === 5 && <PricingPlan />}
              {selectedTab === 6 && <Guidelines />}
            </Tabs>
          </Page>
          <Tooltip active content="Chat on Discord" hasUnderline>
            <div style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
              <a href="https://discord.gg/cHr2AyvT" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} >
                <button style={{
                  backgroundColor: '#000000',
                  color: 'white',
                  border: 'none',
                  borderRadius: '50%',
                  width: '60px',
                  height: '60px',
                  cursor: 'pointer',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  fontSize: '0'
                }}>
                  <Image src={require(`./assets/discord_icon.png`)} alt="Discord Icon" style={{ width: `30px`, height: `25px` }} />
                </button>
              </a>
            </div>
          </Tooltip>
        </Frame>
      {/* </AppBridgeProvider> */}
    </AppProvider>
  );
}

export default App;
