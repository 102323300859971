import React, { useState } from "react";
import { Form, FormLayout, Layout, Card, TextField, Button } from "@shopify/polaris";

export default function DiscordButtonEmbed() {
  
  const originUrl = `https://${window.location.hostname}`;
  const [discordName, setdiscordName] = useState("Login with Discord");
  const [discordWidth, setdiscordWidth] = useState(200);
  const [discordHeight, setdiscordHeight] = useState(50);
  const [buttonText, setButtonText] = useState('Copy Embed Icon');
  const discordButtonCode = `
    <center>
      <a href="${originUrl}/auth/discord?shop={{shop.permanent_domain}}&hostdomain={{request.host}}" style="display: inline-block; background-color: #7289da; color: white; font-size: 16px; padding: 0; margin: 0; border-radius: 8px; text-decoration: none; cursor: pointer; width: ${discordWidth}px; height: ${discordHeight}px; display: flex; align-items: center; justify-content: center;">
        <i style="padding-right: 10px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256">
            <g fill="#ffffff" fill-rule="nonzero">
              <g transform="scale(5.33333)">
                <path d="M40,12c0,0 -4.585,-3.588 -10,-4l-0.488,0.976c4.896,1.198 7.142,2.915 9.488,5.024c-4.045,-2.065 -8.039,-4 -15,-4c-6.961,0 -10.955,1.935 -15,4c2.346,-2.109 5.018,-4.015 9.488,-5.024l-0.488,-0.976c-5.681,0.537 -10,4 -10,4c0,0 -5.121,7.425 -6,22c5.162,5.953 13,6 13,6l1.639,-2.185c-2.782,-0.967 -5.924,-2.694 -8.639,-5.815c3.238,2.45 8.125,5 16,5c7.875,0 12.762,-2.55 16,-5c-2.715,3.121 -5.857,4.848 -8.639,5.815l1.639,2.185c0,0 7.838,-0.047 13,-6c-0.879,-14.575 -6,-22 -6,-22zM17.5,30c-1.933,0 -3.5,-1.791 -3.5,-4c0,-2.209 1.567,-4 3.5,-4c1.933,0 3.5,1.791 3.5,4c0,2.209 -1.567,4 -3.5,4zM30.5,30c-1.933,0 -3.5,-1.791 -3.5,-4c0,-2.209 1.567,-4 3.5,-4c1.933,0 3.5,1.791 3.5,4c0,2.209 -1.567,4 -3.5,4z"></path>
              </g>
            </g>
          </svg>
        </i>
        <span style="font-weight: bold;">${discordName}</span>
      </a>
    </center>
  `;

  const handleCopyClick = () => {
    const textarea = document.createElement('textarea');
    textarea.value = discordButtonCode;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    setButtonText('Code Copied!');
    setTimeout(() => {
      setButtonText('Copy Embed Icon');
    }, 2000);
  };

  return (
      <Layout>
        <Layout.Section
          title="Embed Discord Button Preview "
          description="View Embeded discord icons"
        >
          <Card title="Discord Preview" sectioned>
            <Form>
            <FormLayout>
              <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                <div dangerouslySetInnerHTML={{ __html: discordButtonCode }} />
                <Button onClick={handleCopyClick}>{buttonText}</Button>
              </div>
              <div style={{ display: 'flex', gap: '16px' }}>
                <TextField
                  label="Enter Name"
                  type="name"
                  value={discordName}
                  onChange={setdiscordName}
                />
                <TextField
                  label="Width"
                  type="number"
                  value={discordWidth}
                  onChange={setdiscordWidth}
                />
                <TextField
                  label="Height"
                  type="number"
                  value={discordHeight}
                  onChange={setdiscordHeight}
                />
              </div>
            </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
      </Layout>
  );
}
