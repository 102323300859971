import React, { useState, useEffect } from "react";
import { Page, Layout, Card, FormLayout, TextField, Checkbox, Frame, Divider, Spinner, Toast } from "@shopify/polaris";

export default function DiscordNotification() {

  const storeDomain = new URL(window.location).searchParams.get("shop");
  const [loading, setLoading] = useState(false);
  const [enableNotification, setEnableNotification] = useState(false);
  const [discordChannelID, setDiscordChannelID] = useState("");
  const [enableOrderPaid, setEnableOrderPaid] = useState(false);
  const [orderPaidMessage, setOrderPaidMessage] = useState("New order paid: Order ##orderNumber##, Total: ##totalPrice##, Customer: ##customerName##\nView Order: ##orderUrl##");
  const [enableCustomerCreate, setEnableCustomerCreate] = useState(false);
  const [customerCreateMessage, setCustomerCreateMessage] = useState("New customer created name: ##customerName##, email: ##customerEmail##");
  const [active, setActive] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/get_data?shop=${storeDomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data) {
            const dataStore = data.custom.discord_notifications;
            setEnableNotification(dataStore.enableNotification);
            setDiscordChannelID(dataStore.discordChannelID);
            setEnableOrderPaid(dataStore.enableOrderPaid);
            setOrderPaidMessage(dataStore.orderPaidMessage);
            setEnableCustomerCreate(dataStore.enableCustomerCreate);
            setCustomerCreateMessage(dataStore.customerCreateMessage);
          }
          console.log(response.status);
        } else {
          console.error(response.status);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [storeDomain]);

  const handleSubmit = async () => {

    setLoading(true);
    const discord_notifications = {
      enableNotification,
      discordChannelID,
      enableOrderPaid,
      orderPaidMessage,
      enableCustomerCreate,
      customerCreateMessage,
    }

    setActive((active) => !active);
    const response = await fetch(`/api/update_config?shop=${storeDomain}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'x-shopify-shop-domain': storeDomain
      },
      body: JSON.stringify({discord_notifications}),
    });

    if (response.ok) {
      console.log(response.status);
    } else {
      console.error('Failed to save settings');
    }
    setLoading(false);
    return;
  };

  const handleDismiss = async () => {
    setActive((active) => !active);
  };

  const toastMarkup = active ? (
    <Toast content="Saved Settings Successfully" onDismiss={handleDismiss} />
  ) : null;

  return (
    <div style={{ height: '250px' }}>
      <Frame>
        <Page
          title="Notification on Discord Server Admin"
          primaryAction={{ content: loading ? <Spinner size="small" color="teal" /> : 'Save Settings', onAction: handleSubmit }}
        >
          <Layout>
              <Layout.Section>
                <Card>
                  <FormLayout>
                    <Checkbox
                      label="Enable Discord Notifications"
                      checked={enableNotification}
                      onChange={() => setEnableNotification(!enableNotification)}
                    />
                    <TextField
                      label="Discord Server Admin ID"
                      value={discordChannelID}
                      onChange={setDiscordChannelID}
                      disabled={!enableNotification}
                    />
                    <Divider />
                    <Checkbox
                      label="Enable Orders Paid Notifications"
                      checked={enableOrderPaid}
                      onChange={() => setEnableOrderPaid(!enableOrderPaid)}
                    />
                    <TextField
                      label="Order Paid Notification Message with Parameters (##orderNumber##, ##totalPrice##, ##customerName##, ##orderUrl##, ##productUrl##)"
                      value={orderPaidMessage}
                      onChange={setOrderPaidMessage}
                      multiline={3}
                      disabled={!enableOrderPaid}
                    />
                    <Divider />
                    <Checkbox
                      label="Enable Customers Create Notifications"
                      checked={enableCustomerCreate}
                      onChange={() => setEnableCustomerCreate(!enableCustomerCreate)}
                    />
                    <TextField
                      label="Customers Create Notification Message with Parameters (##customerName##, ##customerEmail##)"
                      value={customerCreateMessage}
                      onChange={setCustomerCreateMessage}
                      multiline={3}
                      disabled={!enableCustomerCreate}
                    />
                  </FormLayout>
                </Card>
              </Layout.Section>
          </Layout>
          {toastMarkup}
        </Page>
        <Page></Page>
        <Page></Page>
        <Page></Page>
      </Frame>
    </div>
  );
}
