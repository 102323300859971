import { useState, useEffect } from 'react';
import { DataTable, Card, Button, Frame, Page } from '@shopify/polaris';

export default function PricingPlan() {

  const storeDomain = new URL(window.location).searchParams.get("shop");
  const [activeProPlans, setActiveProPlans] = useState([]);
  const [activeBasicPlans, setActiveBasicPlans] = useState([]);

  useEffect(() => {
    const fetchActivePlans = async () => {
      try {
        const response = await fetch(`/api/active_plans?shop=${storeDomain}`, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
          },
        });
        const data = await response.json();
        if(data[0].name === "Basic Plan"){
          setActiveBasicPlans(data[0].status);
        } else if (data[0].name === "Premium Plan"){
          setActiveProPlans(data[0].status);
        }
      } catch (error) {
        console.error('Error fetching active plans:', error);
      }
    };
  
    fetchActivePlans();
  }, [storeDomain]);

  const handleBasicPlanClick = async () => {

    try {
      const response = await fetch(`/api/basic_plan?shop=${storeDomain}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-shopify-shop-domain': storeDomain
        },
      });
  
      if (response.ok) {
        const confirmationUrl = await response.text();
        window.open(confirmationUrl, '_blank');
      } else {
        console.log('Failed to get confirmation URL.');
      }
    } catch (error) {
      console.error('Error handling basic plan click:', error);
    }
  };  

  const handlePremiumPlanClick = async () => {

    try {
      const response = await fetch(`/api/premium_plan?shop=${storeDomain}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-shopify-shop-domain': storeDomain
        },
      });
  
      if (response.ok) {
        const confirmationUrl = await response.text();
        window.open(confirmationUrl, '_blank');
      } else {
        console.log('Failed to get confirmation URL.');
      }
    } catch (error) {
      console.error('Error handling basic plan click:', error);
    }
  };

  const activeFreePlanCell = <center><Button disabled>Free Plan</Button></center>;
  const activeBasicPlanCell = activeBasicPlans === 'active' ? <center style={{ color: 'green' }}>Active Basic Plan</center> : <center><Button variant="primary" onClick={handleBasicPlanClick}>Upgrade to Basic</Button></center>;
  const activeProPlanCell = activeProPlans === 'active' ? <center style={{ color: 'green' }}>Active Premium Plan</center> : <center><Button variant="primary" onClick={handlePremiumPlanClick}>Upgrade to Premium</Button></center>;

  const greentick = <center><span style={{ color: 'green' }}>✓</span></center>;
  const blanktick = <center><span style={{ color: 'red' }}>✖</span></center>;
  const rows = [
    ['Pricing Plans',<center><b>Free Forever</b></center>, <center><b>$19/month</b></center>, <center><b>$79/month</b></center>],
    ['Discord Users Login', <center>100/month</center>, <center>Unlimited</center>, <center>Unlimited</center>],
    ['Custom Developer App', greentick, greentick, greentick],
    ['Redirection on Login', greentick, greentick, greentick],
    ['Multipass Token Login', greentick, greentick, greentick],
    ['Add Discord Member on Registration', blanktick, greentick, greentick],
    ['Add Member Role on Registration', blanktick, greentick, greentick],
    ['Assign Role Based on Customers Tags', blanktick, blanktick, greentick],
    ['Discord Account Linking', blanktick, blanktick, greentick],
    ['Discord Invite Link', blanktick, blanktick, greentick],
    ['Discord Event Notification', blanktick, blanktick, greentick],
    ['Subcription Based Role in Discord', blanktick, blanktick, greentick],
    ['Upgrade Plan', activeFreePlanCell, activeBasicPlanCell, activeProPlanCell],
  ];

  const columns = [
    {
      header: 'Features List',
      accessor: 0,
    },
    {
      header: <center>Free Plan</center>,
      accessor: 1,
    },
    {
      header: <center>Basic Plan</center>,
      accessor: 2,
    },
    {
      header: <center>Premium Plan</center>,
      accessor: 3,
    }
  ];

  return (
    <div style={{ height: '500px' }}>
      <Frame>
        <Page
          title="Pricing Plan"
        >
          <Card sectioned>
            <DataTable
              columnContentTypes={['text', 'text', 'text', 'text', 'text']}
              headings={columns.map((column) => column.header)}
              rows={rows}
            />
          </Card>
        <Page></Page>
        <Page></Page>
        <Page></Page>
        </Page>
      </Frame>
  </div>
  );
};
