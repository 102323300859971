import React, { useState } from "react";
import { Banner, Modal, InlineStack, TextField, Spinner, Button, Toast } from "@shopify/polaris";

export default function RatingBanner() {
  
  const storeDomain = new URL(window.location).searchParams.get("shop");
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [selectedRating, setSelectedRating] = useState(0);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isSubmittingSuggestion, setIsSubmittingSuggestion] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const handleSuggestionSubmit = async () => {
    
    try {
      const postData = { message: feedbackText };
      setIsSubmittingSuggestion(true);

      const response = await fetch(`/api/contact_support?shop=${storeDomain}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      if (response.ok) {
        setShowSuccessToast(true);
        setTimeout(() => {
          setShowSuccessToast(false);
        }, 3000);
        
        console.log(response.status);
      } else {
        console.error(response.status);
      }

      console.log("Suggestion submitted:", feedbackText);
      setIsFeedbackModalOpen(!isFeedbackModalOpen);
    } catch (error) {
      console.error("Error submitting suggestion:", error);
    } finally {
      setIsSubmittingSuggestion(false);
    }
  };

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  const handleEmojiClick = (rating) => {
    console.log("Emoji clicked:", rating);
    setSelectedRating(rating);
    if(rating === 5){
      const url = "https://apps.shopify.com/discord-connect#modal-show=WriteReviewModal";
      return window.open(url, "_blank");
    }
    setIsFeedbackModalOpen(true);
  };

  const renderEmojis = () => {
    const maxRating = 5;
    const emojis = ["😞", "😐", "😊", "😃", "😄"];
    const emojiLabels = ["Sad", "Neutral", "Happy", "Very Happy", "Delighted"];
    const emojiInlineStack = [];

    for (let i = 1; i <= maxRating; i++) {
      const isSelected = i <= selectedRating;
      const emoji = isSelected ? emojis[i - 1] : "⭐️";
      const label = emojiLabels[i - 1];

      emojiInlineStack.push(
        <span
          key={i}
          role="img"
          aria-label={label}
          onClick={() => handleEmojiClick(i)}
          style={{
            fontSize: "30px",
            cursor: "pointer",
            marginRight: "5px",
            color: isSelected ? "#FFD700" : "#DDDDDD",
          }}
        >
          {emoji}
        </span>
      );
    }

    return emojiInlineStack;
  };

  return (
    <div>
      {isBannerVisible && (
        <Banner
          title="We'd love to hear your feedback! Please rate our app."
          status="info"
          onDismiss={handleCloseBanner}
        >
          <InlineStack alignment="center">{renderEmojis()}</InlineStack>
        </Banner>
      )}

      {isFeedbackModalOpen && (
        <Modal
          open={isFeedbackModalOpen}
          onClose={() => setIsFeedbackModalOpen(false)}
          title="Provide a Suggestion"
        >
          <Modal.Section>
            <TextField
              label="How can we improve our app?"
              value={feedbackText}
              onChange={(value) => setFeedbackText(value)}
              multiline={4}
              required
            />
          </Modal.Section>
          <Modal.Section>
            <Button
              onClick={handleSuggestionSubmit}
              primary
              disabled={isSubmittingSuggestion}
            >
              {isSubmittingSuggestion ? (
                <Spinner size="small" color="white" />
              ) : (
                "Submit Suggestion"
              )}
            </Button>
          </Modal.Section>
        </Modal>
      )}

      {showSuccessToast && (
        <Toast
          content="Feedback submitted successfully!"
          onDismiss={() => setShowSuccessToast(false)}
        />
      )}
    </div>
  );
}
