import React, { useState, useEffect } from 'react';
import { Page, Card, Layout, Label, FormLayout, Tabs, BlockStack, Image, Button, Link, Text } from '@shopify/polaris';
import { ExternalMinor } from '@shopify/polaris-icons';
import { discordCreateApp, discordEnableLoginOption, discordLoginIcon, discordClientId, discordAppClientId, discordCustomization, discordTestConfiguration,
  discordAddBot, discordAddNewServer, discordAddedBot, discordAuthorizebot, discordBotScope, discordCreateSever, discordCopyUrl, discordEnterNameServer, discordGenerateBot } from "../assets";
  
export default function Guidelines() {

  const [selectedTab, setSelectedTab] = useState('tab1');
  const storeDomain = new URL(window.location).searchParams.get("shop");
  const themeAppUrl = `https://${storeDomain}/admin/themes/current/editor?&context=apps&template=customers/login&appEmbed=b5fd0c86-4525-4284-bed9-4483e61bdbed/app-block&target=mainSection`;
  const originUrl = `https://${window.location.hostname}`;
  const callbackUrl = `${originUrl}/auth/discord/callback/`;
  const setupvideo = "https://www.youtube.com/embed/W1iw4Et-f8o";
  const discordUrl = <Link url="https://discord.com/channels/@me" target='_blank'>Discord Channel</Link>
  const discordDeveloperURL = <Link url="https://discord.com/developers/applications" target='_blank'>Discord Developer Portal</Link>
  const [callbackId, setCallbackId] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/get_data?shop=${storeDomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data.id) {
            setCallbackId(data.id);
          }
          console.log(response.status);
        } else {
          console.error(response.status);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [storeDomain]);

  const handleTabChange = (selectedTabIndex) => {
    const tabId = `tab${selectedTabIndex + 1}`;
    setSelectedTab(tabId);
  };

  const renderTabContent = () => {
    if (selectedTab === 'tab1') {
      return (
        <div id="tab1-panel">
          <FormLayout>
            <Text variant="headingLg" as="h5">Setup Video for Discord Login on Shopify Store</Text>
              <iframe src={setupvideo} title="Video" frameBorder="0" allowFullScreen style={{ width: '90%', height: '400px' }}></iframe>
              <li>Ensure that you have already enable login on shopify store</li>
              <Button url={`https://${storeDomain}/admin/settings/customer_accounts`} target="_blank" variant="primary" icon={ExternalMinor}>Enable Login on Store</Button>
            <BlockStack alignment="center">
              <Image
                source={discordEnableLoginOption}
                alt="Enable Login Discord"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
              <li>First, you'll need to create an app in the developer portal if you don't have one already:</li>
              <Button url="https://discord.com/developers/applications?new_application=true" target="_blank" variant="primary" icon={ExternalMinor}>Create App</Button>
            <BlockStack alignment="center">
              <Image
                source={discordCreateApp}
                alt="Discord Create App"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
              <li>Enter a name for your app, then press <b>Create</b>. On the left sidebar, click on <b>OAuth2</b>.</li>
              <li>Under the <b>Redirects</b> section, add the redirect URL <b>{callbackUrl}{callbackId}</b>.</li>
              <li>Scroll down to the <b>OAuth2 URL Generator</b> section.</li>
            <BlockStack alignment="center">
              <Image
                source={discordClientId}
                alt="Discord Client ID"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
              <li>You have now generated a <b>Client ID</b> and <b>Client Secret</b> for your Discord app.</li>
            <BlockStack alignment="center">
              <Image
                source={discordAppClientId}
                alt="Discord Client Secret"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
              <li>Set scope as <b>identify+email</b> if you want to add member to server then change it to <b>identify+email+guilds.join</b>.</li>
            <BlockStack alignment="center">
              <Image
                source={discordTestConfiguration}
                alt="Discord Test Connection"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
              <li>Click on <b>Test Connection</b> button and check user detials so that you can make sure that your configuration is correct.</li>
              <li>Navigate to <b>Online Store -{'>'} Theme -{'>'} Customize</b> page to edit discord icon. </li>
              <li>OR Click on <b>Customize Theme Section</b> to make changes in discord icon on login page frontend.</li>
              <Button url={themeAppUrl} target="_blank">Customize Theme Section</Button>
            <BlockStack alignment="center">
              <Image
                source={discordCustomization}
                alt="Discord Login Custom Icon"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
              <li>Now you will able to see discord icons on your shopify store login page.</li>
            <BlockStack alignment="center">
              <Image
                source={discordLoginIcon}
                alt="Discord Login Icon"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
          </FormLayout>
        </div>
      );
    } else if (selectedTab === 'tab2') {
      return (
        <div id="tab2-panel">
          <FormLayout>
            <Text variant="headingLg" as="h5">Step to Configuration Discord Bot Integration</Text>
              <li>Go to {discordUrl} on your browser</li>
              <li>Create Discord Server By clicking on plus button for <b>Add a Server</b>.</li>
            <BlockStack alignment="center">
              <Image
                source={discordAddNewServer}
                alt="Discord Add New"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
              <li>Select <b>Create My Own</b> for creating server.</li>
            <BlockStack alignment="center">
              <Image
                source={discordCreateSever}
                alt="Example Image"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
              <li>Enter valid name for Discord Server and click on <b>Create</b> button.</li>
            <BlockStack alignment="center">
              <Image
                source={discordEnterNameServer}
                alt="Example Image"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
            <Label>Step 2: Navigate {discordDeveloperURL} link to get bot token</Label>
              <li>Click on <b>Add Bot</b> button, Then click on <b>Reset Token</b> button and copy <b>Bot Token</b> form here.</li>
            <BlockStack alignment="center">
              <Image
                source={discordGenerateBot}
                alt="Example Image"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
              <li>Go to <b>URL Generator</b> section and select given scopes <b>identify, guilds, gdm.join, email, guilds.join, rpc, bot</b>.</li>
              <li>Scroll down on same page select <b>Redirect URL</b> and <b>Bot</b> checkbox in permission section.</li>
            <BlockStack alignment="center">
              <Image
                source={discordBotScope}
                alt="Example Image"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
              <li>Scroll down on same page select <b>Redirect URL</b> and <b>Bot</b> checkbox in permission section.</li>
            <BlockStack alignment="center">
              <Image
                source={discordCopyUrl}
                alt="Example Image"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
              <li>Copy generated url and paste it in browser new tab and <b>Continue</b> so bot can get add into discord server.</li>
            <BlockStack alignment="center">
              <Image
                source={discordAuthorizebot}
                alt="Example Image"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
              <li>You can now check <b>Discord Channel</b> page where you will get notification that <b>BOT</b> has been added.</li>
            <BlockStack alignment="center">
              <Image
                source={discordAddBot}
                alt="Example Image"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
              <li>By going to server settings click on <b>Members</b> section to manage <b>BOT</b> users.</li>
            <BlockStack alignment="center">
              <Image
                source={discordAddedBot}
                alt="Example Image"
                style={{ maxWidth: '95%', marginTop: '1rem' }}
              />
            </BlockStack>
              <li>Now this settings will allow you to add <b>Member</b> and <b>Role</b> on Discord login/registration process.</li>
              <br />
          </FormLayout>
        </div>
      );
    }
  };

  return (
    <Page>
      <Layout>
        <Card title="Step By Step Gudilines to Configure Discord Login">
          <Tabs tabs={[
              {
                id: 'tab1',
                content: 'Discord Developer Application',
              },
              {
                id: 'tab2',
                content: 'Discord Bot Integration',
              },
            ]}
            selected={selectedTab}
            onSelect={handleTabChange}
          />
          <Card>
            {renderTabContent()}
          </Card>
        </Card>
      </Layout>
      <Page></Page>
      <Page></Page>
      <Page></Page>
    </Page>
  );
};
