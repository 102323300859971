import React, { useState, useEffect }from "react";
import { Modal, Form, FormLayout, TextField, Button, Spinner } from "@shopify/polaris";

export default function SupportForm({ open, onClose }) {

  const storeDomain = new URL(window.location).searchParams.get("shop");
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/get_data?shop=${storeDomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
          },
        });
        if (response.ok) {
          const data = await response.json();
          if(data.storeDetails){
            setName(data.storeDetails.shop_owner);
            setEmail(data.storeDetails.email);
            setMessage("Need help with Discord Setup");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

  }, [storeDomain]);

  const handleSubmitSupport = async () => {

    setLoading(true);
    const postData = { name, email, message };
    const response = await fetch(`/api/contact_support?shop=${storeDomain}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'x-shopify-shop-domain': storeDomain
      },
      body: JSON.stringify(postData),
    });

    if (response.ok) {
      console.log(response.status);
      setLoading(false);
      onClose(); // Close the modal after successful submission
    } else {
      console.error(response.status);
      setLoading(false);
    }
  };

  return (
    <Modal open={open} title="How can we help?" onClose={onClose}>
      <Modal.Section>
        <Form onSubmit={handleSubmitSupport}>
          <FormLayout>
            <TextField
              label="Name (optional)"
              placeholder="Enter Your Name"
              value={name}
              onChange={(value) => setName(value)}
              required
            />
            <TextField
              label="Email*"
              type="email"
              placeholder="Enter Your Email"
              value={email}
              onChange={(value) => setEmail(value)}
              required
            />
            <TextField
              label="Message*"
              placeholder="Enter Your Message or Query"
              value={message}
              onChange={(value) => setMessage(value)}
              multiline={4}
              required
            />
            <Button variant="primary" submit>
              {loading ? <Spinner size="small" /> : "Submit"}
            </Button>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
}
